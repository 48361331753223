import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

const ProtectedRoute = ({ children }) => {
    const { user } = useAuthenticator();
    const location = useLocation();

    if (!user) {
        // Redirect them to the /login page, but save the current location they were trying to go to
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return children;
};
export default ProtectedRoute;