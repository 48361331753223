export function RedPage() {
    return (
        <div className="page alert my-5 sm:w-3/5 4/5 mx-auto text-center p-4"
             role="alert">
            <h1 className="text-3xl mb-5">Ouch! We are unable to offer you treatment at this time </h1>
            <p className="mb-4 text-xl">Based on the assessment, it is crucial that you consult a physician as soon as
                possible.
                Your symptoms require in-person professional medical evaluation and may need urgent care.</p>
            <p className="font-extralight mb-4">Please do not delay in seeking medical advice from your healthcare
                provider to ensure your safety and
                well-being.</p>

            <button className="bg-teal-700 text-white font-bold py-2 px-4 rounded m-2"
                    onClick={() => window.location.href = '/book-appointment'}>Book an Appointment
            </button>
            <button className="bg-gray-300 text-teal-700 font-bold py-2 px-4 rounded m-2"
                    onClick={() => window.location.href = '/'}>Return to Homepage
            </button>
        </div>
    );
}
