import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import {
  UserPatient,
  PainAssessment,
  TreatmentPlan,
  Patient,
} from "../../models";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';

const checkRole = async () => {
  try {
    const { tokens } = await fetchAuthSession();
    const group = tokens?.accessToken?.payload?.["cognito:groups"] || [];
    if (group.includes('HCPs')) {
      console.log("User belongs to 'Doctors' group.");
      return 'doc';
    }
    else if (group.includes('Admin')) {
      console.log("User belongs to 'Admin' group.");
      return 'admin';
    }
  } catch (err) {
    console.error("User does not belong to a group:", err);
    return 'user';
  }
};

const Dashboard = () => {
  const [treatmentPlans, setTreatmentPlans] = useState([]);
  const [filteredTreatmentPlans, setFilteredTreatmentPlans] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedRows, setExpandedRows] = useState({});
  const [isCheckingRole, setIsCheckingRole] = useState(true);
  
  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  

  useEffect(() => {
    const checkAndRedirect = async () => {
      if (!user) {
        navigate('/login');
        return;
      }

      try {
        const role = await checkRole(); // Your existing role check function
        if (role === 'doc') {
          navigate('/docdashboard');
          console.log(role);
        } else if (role === 'admin') {
          navigate('/admin');
          console.log(role);
        } else {
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('Error checking role:', error);
        setError('Failed to verify user access');
      } finally {
        setIsCheckingRole(false);
      }
    };

    checkAndRedirect();
  }, [user, navigate]);
  
    useEffect(() => {
    if (statusFilter === "All") {
      setFilteredTreatmentPlans(treatmentPlans);
    } else {
      const filtered = treatmentPlans.filter(
        (plan) =>
          plan.treatmentPlans.length > 0 &&
          plan.treatmentPlans[0].status === statusFilter
      );
      setFilteredTreatmentPlans(filtered);
    }
  }, [treatmentPlans, statusFilter]);

  // Fetch treatment plans on component mount and user change
  // useEffect(() => {
  //   if (user) {
  //     // Ensure this is the correct identifier as per your user model
  //     console.log("Fetching treatment plans for user:", user.userId);
  //     fetchAllUserTreatmentPlans(user.userId)
  //       .catch((err) => {
  //         setError("Failed to fetch data");
  //         console.error("Error fetching treatment plans:", err);
  //       })
  //       .finally(() => {
  //         console.log("Finished fetching data.");
  //         setLoading(false);
  //       });
  //   } else {
  //     console.log("User not defined yet or missing sub.");
  //     setLoading(false); // Ensures that the loading state is reset even if no user is found
  //   }
  // }, [user, isCheckingRole]);
  useEffect(() => {
    if (user) {
      fetchUserData(user.userId)
        .then(data => {
          setTreatmentPlans(data);
          setLoading(false);
        })
        .catch(err => {
          setError("Failed to fetch data");
          console.error(err);
          setLoading(false);
        });
    }
  }, [user]);

  // Update filteredTreatmentPlans whenever treatmentPlans or searchTerm changes
  useEffect(() => {
    const filteredData = filterTreatmentPlans(treatmentPlans);
    setFilteredTreatmentPlans(filteredData);
  }, [treatmentPlans, searchTerm]);


  // Helper to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  // Calculate total pages
  const pageCount = Math.ceil(filteredTreatmentPlans.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredTreatmentPlans.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const getStatusLabel = (status) => {
    const statusStyles = {
      SEE_DOCTOR: {
        bgColor: "bg-red-100 dark:bg-red-600",
        textColor: "text-white",
        icon: (
          <svg
            className="mr-1 h-3 w-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ),
      },
      PENDING_REVIEW: {
        bgColor: "bg-yellow-100 dark:bg-yellow-400",
        textColor: "text-white",
        icon: (
          <svg
            className="mr-1 h-3 w-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 8v4l3 3M12 2a9 9 0 100 18 9 9 0 000-18z"
            />
          </svg>
        ),
      },
      PAID: {
        bgColor: "bg-green-100 dark:bg-green-500",
        textColor: "text-white",
        icon: (
          <svg
            className="mr-1 h-3 w-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 11.917 9.724 16.5 19 7.5"
            />
          </svg>
        ),
      },
      SHIPPED: {
        bgColor: "bg-blue-100 dark:bg-blue-700",
        textColor: "text-white",
        icon: (
          <svg
            className="mr-1 h-3 w-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 7h6l2 4m-8-4v8m0-8V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v9h2m8 0H9m4 0h2m4 0h2v-4m0 0h-5m3.5 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-10 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
            />
          </svg>
        ),
      },
      UNPAID: {
        bgColor: "bg-orange-700 dark:bg-orange-500",
        textColor: "text-white",
        icon: (
          <svg
            className="mr-1 h-3 w-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            {/*<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.5 4h-13m13 16h-13M8 20v-3.333a2 2 0 0 1 .4-1.2L10 12.6a1 1 0 0 0 0-1.2L8.4 8.533a2 2 0 0 1-.4-1.2V4h8v3.333a2 2 0 0 1-.4 1.2L13.957 11.4a1 1 0 0 0 0 1.2l1.643 2.867a2 2 0 0 1 .4 1.2V20H8Z" />*/}

            {/*<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
            {/*      d="M3 5h18a1 1 0 011 1v12a1 1 0 01-1 1H3a1 1 0 01-1-1V6a1 1 0 011-1zm0 3h18v8H3V8z"/>*/}
            {/*<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 9h4v6H7z"/>*/}
            <rect x="2" y="3" width="20" height="18" rx="2" ry="2"></rect>
            <line x1="2" y1="7" x2="22" y2="7"></line>
            <line x1="6" y1="11" x2="9" y2="11"></line>
            <line x1="6" y1="15" x2="9" y2="15"></line>
            <line x1="6" y1="19" x2="9" y2="19"></line>
            <line x1="13" y1="11" x2="18" y2="11"></line>
            <line x1="13" y1="15" x2="18" y2="15"></line>
            <line x1="13" y1="19" x2="18" y2="19"></line>
          </svg>
        ),
      },
    };

    const defaultStyle = {
      bgColor: "bg-gray-100 dark:bg-gray-700",
      textColor: "text-gray-800 dark:text-gray-300",
      icon: null,
    };
    const { bgColor, textColor, icon } = statusStyles[status] || defaultStyle;
    return (
      <span
        className={`inline-flex items-center rounded px-2.5 py-0.5 ${bgColor} ${textColor}`}
      >
        {icon}
        {formatField(status)}
      </span>
    );
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const maxPageNumberLimit = 5;
    const minPageNumberLimit = 0;

    for (let i = 1; i <= pageCount; i++) {
      if (i > minPageNumberLimit && i < maxPageNumberLimit + 1) {
        pageNumbers.push(i);
      }
    }

    return (
      <nav
        className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
        aria-label="Table navigation"
      >
        <span className="text-sm font-normal text-gray-400 dark:text-gray-500">
          Showing
          <span className="font-semibold mx-1 text-gray-700 dark:text-gray-700">
            {indexOfFirstItem + 1}-{indexOfLastItem}
          </span>
          of
          <span className="font-semibold mx-1 text-gray-700 dark:text-gray-700">
            {filteredTreatmentPlans.length}
          </span>
        </span>
        <ul className="inline-flex items-stretch -space-x-px">
          {currentPage > 1 && (
            <li>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-400 bg-gray-800 rounded-l-lg border border-gray-700 hover:bg-gray-700 hover:text-white dark:text-gray-500 dark:bg-white dark:border-gray-300 dark:hover:bg-gray-100 dark:hover:text-gray-700"
              >
                <span className="sr-only">Previous</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </li>
          )}
          {pageNumbers.map((number) => (
            <li key={number}>
              <button
                onClick={() => handlePageChange(number)}
                className={`flex items-center justify-center text-sm py-2 px-3 leading-tight ${
                  currentPage === number
                    ? "text-primary-700 bg-primary-100 border-primary-300 hover:bg-primary-50 hover:text-primary-600"
                    : "text-gray-400 bg-gray-800 border-gray-700 hover:bg-gray-700 hover:text-white"
                } dark:text-gray-500 dark:bg-white dark:border-gray-300 dark:hover:bg-gray-100 dark:hover:text-gray-700`}
              >
                {number}
              </button>
            </li>
          ))}
          {currentPage < pageCount && (
            <li>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-400 bg-gray-800 rounded-r-lg border border-gray-700 hover:bg-gray-700 hover:text-white dark:text-gray-500 dark:bg-white dark:border-gray-300 dark:hover:bg-gray-100 dark:hover:text-gray-700"
              >
                <span className="sr-only">Next</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </li>
          )}
        </ul>
      </nav>
    );
  };

  const handleCheckout = (treatmentPlanId) => {
    const itemsToPurchase = [{ id: 1, quantity: 1 }];

    fetch("http://localhost:4242/create-checkout-session", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        items: itemsToPurchase,
        treatmentPlanId: treatmentPlanId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Received data from checkout session:", data);
        if (data.url) {
          window.location.href = data.url;
        } else {
          throw new Error("URL is undefined");
        }
      })
      .catch((e) => {
        console.error("Error:", e);
      });
  };

  function formatField(input) {
    // Split the string on underscores, capitalize the first letter of each part, and join them with a space
    return input
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter
      .join(" "); // Join with space
  }


  // async function fetchUserPainAssessments(userId) {
  //   try {
  //     // Fetch the relationships from User to Patient
  //     const userPatients = await DataStore.query(UserPatient, (up) =>
  //       up.userId.eq(userId)
  //     );
  //     const patientIds = userPatients.map((up) => up.patientId);
  
  //     // Fetch all Patients
  //     const patients = await DataStore.query(Patient, (p) =>
  //       p.or((p) => patientIds.map((id) => p.id.eq(id)))
  //     );
  
  //     // Create a map of patientId to patient data
  //     const patientMap = {};
  //     patients.forEach((patient) => {
  //       patientMap[patient.id] = patient;
  //     });
  
  //     // Fetch all Pain Assessments for these patients
  //     const painAssessments = [];
  //     for (const patientId of patientIds) {
  //       const assessments = await DataStore.query(PainAssessment, (pa) =>
  //         pa.patientId.eq(patientId)
  //       );
  
  //       // Attach patient data to each assessment
  //       assessments.forEach((assessment) => {
  //         painAssessments.push({
  //           ...assessment,
  //           patient: patientMap[patientId],
  //         });
  //       });
  //     }
  //     return painAssessments;
  //   } catch (error) {
  //     console.error("Error fetching pain assessments", error);
  //   }
  // }
  
  // async function fetchTreatmentPlansForAssessments(painAssessments) {
  //   try {
  //     const assessmentsWithPlans = [];
  
  //     for (const assessment of painAssessments) {
  //       const plans = await DataStore.query(TreatmentPlan, (tp) =>
  //         tp.painAssessmentId.eq(assessment.id)
  //       );
  
  //       assessmentsWithPlans.push({
  //         ...assessment,
  //         treatmentPlans: plans,
  //       });
  //     }
  //     return assessmentsWithPlans;
  //   } catch (error) {
  //     console.error("Error fetching treatment plans for assessments", error);
  //     throw error;
  //   }
  // }
  
  // async function fetchAllUserTreatmentPlans(userId) {
  //   try {
  //     const painAssessments = await fetchUserPainAssessments(userId);
  //     const combinedData = await fetchTreatmentPlansForAssessments(painAssessments);
  //     setTreatmentPlans(combinedData);
  //   } catch (error) {
  //     console.error("Error fetching all user treatment plans", error);
  //     setError("Failed to fetch treatment plans: " + error.message);
  //     setLoading(false);
  //   }
  // }

  async function fetchUserData(userId) {
    try {
      // Get patients directly through User->Patient relationship
      const patients = await DataStore.query(Patient, p => 
        p.userPatientsId.eq(userId)
      );
  
      // Get assessments for all patients 
      const assessmentsWithDetails = [];
      for (const patient of patients) {
        const assessments = await DataStore.query(PainAssessment, pa =>
          pa.patientId.eq(patient.id)
        );
  
        for (const assessment of assessments) {
          const treatmentPlans = await DataStore.query(TreatmentPlan, tp =>
            tp.painAssessmentId.eq(assessment.id)
          );
  
          if (treatmentPlans.length > 0) {
            assessmentsWithDetails.push({
              ...assessment,
              patient,
              treatmentPlans
            });
          }
        }
      }
  
      return assessmentsWithDetails;
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
    }
  }
  

  const toggleDetails = (index) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const filterTreatmentPlans = (data) => {
    if (!searchTerm) {
      return data; // If no search term, return all data
    }

    return data.filter((item) => {
      // Filter based on patient name (you can adjust this logic as needed)
      const patientNameLower = item.patientName.toLowerCase();
      return patientNameLower.includes(searchTerm.toLowerCase());
    });
  };


  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

    // Show loading state while checking role
    if (isCheckingRole) {
      return <div>Verifying access...</div>;
    }

  return (
    <div>
      <h1 className="p-5 ">Pain Assessment History</h1>
      {/*<h2 className="px-5 ">Welcome, {user.signInDetails.loginId}!</h2>*/}
      <section className="container px-4 my-16 mx-auto">
        <div className="flex flex-col">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
                <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                  <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                    <form className="flex items-center w-full md:w-1/2">
                      <label form="simple-search" className="sr-only">
                        Search
                      </label>
                      <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </div>
                        <input
                          type="text"
                          id="simple-search"
                          className="bg-gray-700 border border-gray-600 text-black text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5 dark:bg-gray-50 dark:border-gray-300 dark:text-gray-900 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Search Patients"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                    </form>
                    <div className="relative">
                      <button
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                        className="inline-flex justify-center w-full rounded-md border shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          className="h-4 w-4 mr-2 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Filter
                        <svg
                          className="ml-1.5 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: 0,
                          transform: "translateX(0%)",
                          width: "100%",
                          maxHeight: "125px",
                          overflow: "auto",
                        }}
                        className={`${
                          dropdownOpen ? "block" : "hidden"
                        } origin-top-right absolute mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none`}
                      >
                        <ul className="py-1 text-sm text-gray-700 dark:text-gray-700">
                          <li>
                            <button
                              onClick={() => setStatusFilter("All")}
                              className="block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              All
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => setStatusFilter("SEE_DOCTOR")}
                              className="block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              See Doctor
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => setStatusFilter("PENDING_REVIEW")}
                              className="block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              Pending Review
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => setStatusFilter("UNPAID")}
                              className="block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              Unpaid
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => setStatusFilter("PAID")}
                              className="block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              Paid
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => setStatusFilter("SHIPPED")}
                              className="block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              Shipped
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-teal-700 ">
                      <tr>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-lg font-bold text-left rtl:text-right text-white"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-lg font-bold text-left rtl:text-right text-white"
                        >
                          Patient
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-lg font-bold text-left rtl:text-right text-white"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-lg font-bold text-left rtl:text-right text-white"
                        >
                          Presenting Symptoms
                        </th>
                        <th scope="col" className="relative py-3.5 px-4">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>

                   <tbody className="bg-white divide-y divide-gray-300">
                    {filteredTreatmentPlans.length > 0 ? (
                      filteredTreatmentPlans.map((item, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            {/* Existing table cells */}
                            <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
                              {item.treatmentPlans[0].createdAt
                                ? formatDate(item.treatmentPlans[0].createdAt)
                                : "No date"}
                            </td>
                            <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
                              {formatField(item.patient.name)}
                            </td>
                            <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
                              {getStatusLabel(item.treatmentPlans[0].status)}
                            </td>
                            <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
                              {item.presentingSymptoms && item.presentingSymptoms.length > 0
                                ? formatField(item.presentingSymptoms[0])
                                : "No Symptoms Reported"}
                            </td>
                            <td className="py-4 flex justify-end px-4 text-base whitespace-nowrap">
                              <button
                                onClick={() => toggleDetails(index)}
                                className="text-teal-700 transition-colors duration-200 hover:text-teal-900 focus:outline-none"
                              >
                                {expandedRows[index] ? "Collapse" : "View"}
                              </button>
                            </td>
                          </tr>
                          {/* Always render the details row */}
                          <tr>
                            <td colSpan="5" className="p-0">
                              <div
                                className={`overflow-hidden transition-all duration-500 ${
                                  expandedRows[index] ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
                                }`}
                              >
                                <div className="p-4 bg-gray-100">
                                  <h3 className="text-lg font-semibold">
                                    Patient and Treatment Details
                                  </h3>
                                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-sm">
                                    <div>
                                      <h4 className="font-semibold">Patient Information</h4>
                                      <p>
                                        <strong>Name:</strong> {formatField(item.patient.name)}
                                      </p>
                                      {/* Additional patient details */}
                                    </div>
                                    <div>
                                      <h4 className="font-semibold">Pain Assessment</h4>
                                      <p>
                                        <strong>Assessment Date:</strong>{" "}
                                        {formatDate(item.treatmentPlans[0].createdAt)}
                                      </p>
                                      <p>
                                        <strong>Symptoms:</strong>{" "}
                                        {formatField(item.presentingSymptoms.join(", "))}
                                      </p>
                                    </div>
                                    <div>
                                      <h4 className="font-semibold">Treatment Plan</h4>
                                      <p>
                                        <strong>Status:</strong>{" "}
                                        {getStatusLabel(item.treatmentPlans[0].status)}
                                      </p>
                                      <p>
                                        <strong>Recommendation:</strong>{" "}
                                        {item.treatmentPlans[0].recommendedTreatment}
                                      </p>
                                      <p>
                                        <strong>Comments:</strong>{" "}
                                        {item.treatmentPlans[0].additionalComments || "N/A"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center py-4">
                          No treatment plans found.
                        </td>
                      </tr>
                    )}
                  </tbody>

                  </table>
        
                  {renderPagination()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;