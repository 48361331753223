import SiteFooter from './components/Common/SiteFooter';
import HomePage from './components/home/HomePage';
import LoginPage from './components/auth/LoginPage';
import ProtectedRoute from './components/Common/ProtectedRoute';
import Dashboard from './components/Pages/Dashboard';
import DocDashboard from './components/Pages/DocDashboard';
import Admin from './components/Pages/Admin';
import SubmitRequest from "./components/Pages/SubmitRequest";
import Contact from "./components/Pages/Contact";
import NavBar from "./components/Common/NavBar";
import About from "./components/Pages/About";
import NotFound from "./components/Common/NotFound";
import Success from "./components/paymentProcessing/Success";
import Cancel from "./components/paymentProcessing/Cancel";
import Storefront from "./components/Pages/Storefront";
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import TermsNConditions from './components/Pages/TermsNConditions';
// import {AmberPage} from "./components/riskLevels/Amber";
// import  {RedPage} from "./components/riskLevels/Red";
// import {GreenPage} from "./components/riskLevels/Green";

import { React, useState, useEffect } from "react"
import { Route, Routes } from 'react-router-dom';

import './App.css';

import type { AppProps } from 'next/app';

import '@aws-amplify/ui-react/styles.css';

import {Authenticator} from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import Cart from "./components/Pages/Cart";
Amplify.configure(config);

function ComingSoonBanner() {
    const [isVisible, setIsVisible] = useState(true);
  
    return isVisible ? (
      <div className="relative bg-gradient-to-r from-teal-600 to-teal-800">
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="pr-16 sm:text-center sm:px-16">
            <p className="font-medium text-white">
              <span className="md:hidden">Coming Soon!</span>
              <span className="hidden md:inline">Coming soon! Stay tuned for updates.</span>
            </p>
          </div>
          <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
            <button
              type="button"
              className="flex p-2 rounded-md hover:bg-teal-500 focus:outline-none focus:ring-2 focus:ring-white"
              onClick={() => setIsVisible(false)}
            >
              <span className="sr-only">Dismiss</span>
              <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    ) : null;
  }

function App({ Component, pageProps }: AppProps) {
  
  return (
      <Authenticator.Provider>
        <ComingSoonBanner />
      <div>
        <NavBar />
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/about" element={<About />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancel" element={<Cancel />} />
            <Route path="/storefront" element={<Storefront />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/submitRequest" element={<SubmitRequest />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsNConditions />} />
            <Route path="/dashboard" element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                } />
            <Route path="/docdashboard" element={
                    <ProtectedRoute>
                        <DocDashboard />
                    </ProtectedRoute>
                } />
            <Route path="/admin" element={
                    <ProtectedRoute>
                        <Admin />
                    </ProtectedRoute>
                } />
            </Routes>
        <SiteFooter />
      </div>
      </Authenticator.Provider >
  );
}
export default App;
