import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import PatientCreateForm from "../forms/createPatient";
import { GreenPage } from "../riskLevels/Green";
import { AmberPage } from "../riskLevels/Amber";
import { RedPage } from "../riskLevels/Red";
import PainAssessmentForm from "../forms/createPainAssessment";
import { TreatmentPlan, Patient, PainAssessment } from '../../models';
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify/datastore";

function SubmitRequest() {
  const [currentStep, setCurrentStep] = useState(0);
  const [readyToProceed, setReadyToProceed] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);
  const [formData, setFormData] = useState({
    patientDetails: {},
    painAssessment: {},
    assessmentResult: '',
    userId: null,
  });
  const [painType, setPainType] = useState('');

  useEffect(() => {
    // Set the userId if user is logged in
    if (user) {
      setFormData(prev => ({ ...prev, userId: user.userId }));
    } else {
      console.log('No user logged in');
      setFormData(prev => ({ ...prev, userId: null }));
    }
  }, [user]);

  // Adjusted steps array to reflect new order
  const steps = [
    { id: 1, name: "Symptom Evaluation" },
    { id: 2, name: "Patient Information" },
    { id: 3, name: "Treatment Plan" },
  ];

  const painTypeField = () => (
    <div>
      <h2 className="mt-24 mb-12 px-12 text-center">
        Please select the type of pain you're experiencing
      </h2>
      <ul className="px-0">
        <li>
          <input
            type="radio"
            id="painType-musculoskeletal"
            name="painType"
            value="musculoskeletal"
            checked={painType === 'musculoskeletal'}
            onChange={(e) => setPainType(e.target.value)}
            className="checkbox-input"
          />
          <label htmlFor="painType-musculoskeletal" className="checkbox-label text-sm">
            Muscle or Joint Strain
          </label>
        </li>
        <li>
          <input
            type="radio"
            id="painType-other"
            name="painType"
            value="other"
            checked={painType === 'other'}
            onChange={(e) => setPainType(e.target.value)}
            className="checkbox-input"
          />
          <label htmlFor="painType-other" className="checkbox-label text-sm">
            Other
          </label>
        </li>
      </ul>
    </div>
  );

  const determineCategory = () => {
    const data = formData.painAssessment;
    let category = 'green'; // Default to 'green' unless conditions below are met

    if (
      data.presentingSymptoms?.includes('NONE') ||
      ['NO_PAIN', 'SEVERE'].includes(data.severityScore) ||
      !data.redFlagSigns?.includes('NONE') ||
      data.onset === 'MORE_THAN_14_DAYS'
    ) {
      category = 'red';
    } else if (
      data.healthHistory?.some(item => item !== 'NONE') ||
      data.allergies?.some(item => item !== 'NONE') ||
      data.additionalNotes?.trim() !== ''
    ) {
      category = 'amber';
    }
    setFormData(prev => ({ ...prev, assessmentResult: category }));
    return category;
  };

  const saveTreatmentPlan = async (category, savedPainAssessment) => {
    const treatmentPlanData = {
      createdAt: new Date().toISOString(),
      status: getRecommendedTreatmentStatus(category),
      recommendedTreatment: getRecommendedTreatment(category),
      painAssessmentId: savedPainAssessment.id,
    };

    try {
      const savedPlan = await DataStore.save(new TreatmentPlan(treatmentPlanData));
      console.log('Treatment plan saved successfully:', savedPlan);
    } catch (error) {
      console.error('Error saving treatment plan:', error);
      alert('Failed to save treatment plan: ' + error.message);
    }
  };

  const getRecommendedTreatmentStatus = (category) => {
    switch (category) {
      case 'green':
        return 'UNPAID';
      case 'amber':
        return 'PENDING_REVIEW';
      case 'red':
        return 'SEE_DOCTOR';
      default:
        return 'Treatment recommendation unavailable';
    }
  };

  const getRecommendedTreatment = (category) => {
    switch (category) {
      case 'green':
        return 'Voltaren Prescribed';
      case 'amber':
        return 'No Medication Prescribed';
      case 'red':
        return 'No Medication Prescribed. Consult with a Doctor';
      default:
        return 'Treatment recommendation unavailable';
    }
  };

  const handleSuccess = (data) => {
    if (currentStep === 1) { // Symptom Evaluation
      setFormData(prev => ({
        ...prev,
        painAssessment: { ...data },
      }));
      handleNextStep(); // Proceed to Patient Information
    } else if (currentStep === 2) { // Patient Information
      setFormData(prev => ({
        ...prev,
        patientDetails: { ...data },
      }));
      setReadyToProceed(true);
    }
  };

  useEffect(() => {
    if (readyToProceed && currentStep === 2) {
      saveData();
      setReadyToProceed(false); // Reset the flag
    }
  }, [readyToProceed, currentStep]);

    // const saveData = async () => {
    //     try {
    //     // Extract patientDetails from formData
    //     const { patientDetails } = formData;
    
    //     // Exclude read-only fields
    //     const { createdAt, updatedAt, ...patientData } = patientDetails;
    
    //     // Save patient data
    //     const savedPatient = await DataStore.save(new Patient(patientData));
    //     console.log('Patient saved:', savedPatient);
    
    //     // Save PainAssessment with patientId
    //     const painAssessmentData = {
    //         ...formData.painAssessment,
    //         patientId: savedPatient.id,
    //     };
    
    //     // Exclude read-only fields from painAssessmentData
    //     const { createdAt: paCreatedAt, updatedAt: paUpdatedAt, ...painAssessmentDataClean } = painAssessmentData;
    
    //     const savedPainAssessment = await DataStore.save(new PainAssessment(painAssessmentDataClean));
    //     console.log('PainAssessment saved:', savedPainAssessment);
    
    //     // Create UserPatient link if user is logged in
    //     if (user) {
    //         const link = await DataStore.save(new UserPatient({
    //         userId: formData.userId,
    //         patientId: savedPatient.id,
    //         patientName: savedPatient.name,
    //         }));
    //         console.log('UserPatient link created', link);
    //     }
    
    //     // Determine category
    //     const category = determineCategory();
    
    //     // Save TreatmentPlan
    //     await saveTreatmentPlan(category, savedPainAssessment);
    
    //     // Proceed to next step
    //     setCurrentStep(currentStep + 1);
    //     } catch (error) {
    //     console.error('Error saving data:', error);
    //     alert('Failed to save data: ' + error.message);
    //     }
    // };
    // const saveData = async () => {
    //   try {
    //     // Create patient with direct user relationship
    //     const patientData = {
    //       ...formData.patientDetails,
    //       userPatientsId: user.userId // Link to user directly
    //     };
    
    //     const savedPatient = await DataStore.save(new Patient(patientData));
    //     console.log('Patient saved:', savedPatient);
    
    //     // Create pain assessment with patient relationship
    //     const painAssessmentData = {
    //       ...formData.painAssessment,
    //       patientId: savedPatient.id,
    //       patientPainAssessmentsId: savedPatient.id
    //     };
    
    //     const savedPainAssessment = await DataStore.save(
    //       new PainAssessment(painAssessmentData)
    //     );
    //     console.log('PainAssessment saved:', savedPainAssessment);
    
    //     // Save treatment plan
    //     const category = determineCategory();
    //     await saveTreatmentPlan(category, savedPainAssessment);
    
    //     setCurrentStep(currentStep + 1);
    //   } catch (error) {
    //     console.error('Error saving data:', error);
    //     throw error;
    //   }
    // };
    const saveData = async () => {
      try {
        // Create patient data without createdAt field
        const { createdAt, updatedAt, ...cleanPatientData } = {
          ...formData.patientDetails,
          userPatientsId: user.userId
        };
    
        const savedPatient = await DataStore.save(new Patient(cleanPatientData));
        console.log('Patient saved:', savedPatient);
    
        // Create pain assessment data without createdAt field
        const { createdAt: paCreatedAt, updatedAt: paUpdatedAt, ...cleanPainAssessmentData } = {
          ...formData.painAssessment,
          patientId: savedPatient.id,
          patientPainAssessmentsId: savedPatient.id
        };
    
        const savedPainAssessment = await DataStore.save(
          new PainAssessment(cleanPainAssessmentData)
        );
        console.log('PainAssessment saved:', savedPainAssessment);
    
        // Save treatment plan
        const category = determineCategory();
        await saveTreatmentPlan(category, savedPainAssessment);
    
        setCurrentStep(currentStep + 1);
      } catch (error) {
        console.error('Error saving data:', error);
        throw error;
      }
    };
  
  const handleNextStep = () => {
    // Check if user needs to sign in
    if (currentStep === 3 && !user) {
      alert('Please sign in to continue');
      return <Authenticator />;
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderAssessmentPage = () => {
    switch (formData.assessmentResult) {
      case 'green':
        return <GreenPage />;
      case 'amber':
        return <AmberPage />;
      case 'red':
        return <RedPage />;
      default:
        return <div>Loading...</div>;
    }
  };

  return (
    <>
      <ol className="flex justify-center my-12 sm:my-24 mx-auto items-center w-full sm:w-5/6 text-2xl font-medium text-center text-teal-700 dark:text-teal-500 md:text-base">
        {steps.map((step, index) => (
          <li
            key={step.id}
            className={`relative flex items-center gap-x-2 sm:flex-row flex-col ${
              step.id <= currentStep
                ? "text-teal-800 dark:text-teal-700"
                : "text-gray-500 dark:text-gray-400"
            }`}
            style={{ width: index < steps.length - 1 ? "calc(100% / 3)" : "auto" }}
          >
            <span className="inline-flex items-center text-xs">
              <span
                className={`size-7 flex justify-center items-center shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full ${
                  step.id <= currentStep
                    ? "bg-teal-600 text-white"
                    : "group-focus:bg-gray-200"
                }`}
              >
                {step.id < currentStep ? (
                  <svg
                    className="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                ) : (
                  <span>{step.id}</span>
                )}
              </span>
              <span className="ms-2 text-sm font-medium">{step.name}</span>
            </span>
            {index < steps.length - 1 && (
              <div className="w-full h-px bg-gray-200 flex-1 mx-2 hidden sm:block"></div>
            )}
          </li>
        ))}
      </ol>
      {currentStep === 0 && (
        <div className="w-4/5 md:w-1/2 mx-auto">
          {painTypeField()}
          <div className="flex justify-center mt-5">
            <button onClick={handleNextStep} className="continue-button">
              Next
            </button>
          </div>
        </div>
      )}
      {currentStep === 1 && (
        <div className="my-5">
          <PainAssessmentForm
            onSuccess={handleSuccess}
            painType={painType}
            className="mt-5"
          />
        </div>
      )}
      {currentStep === 2 && (
        <div className="mt-14">
          <h2 className="mt-24 mb-12 px-12 text-center">
            Before we proceed, we need to know a bit more about you
          </h2>
          <PatientCreateForm
            onSuccess={handleSuccess}
            className="w-4/5 md:w-1/2 mx-auto"
          />
        </div>
      )}
      {currentStep === 3 && (
        <>
          {renderAssessmentPage()}
        </>
      )}
      {currentStep === 4 && (
        <div className="m-5">
          <h2>Done</h2>
          <p>Your request has been submitted successfully.</p>
          <Button onClick={() => setCurrentStep(0)} className="bg-teal-700 mt-3">
            Start New Request
          </Button>
        </div>
      )}
    </>
  );
}

export default SubmitRequest;
