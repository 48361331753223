import { useState } from 'react';
const PainAssessmentForm = ({ onChange, patientDetails = {}, onSuccess }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 9;
    const [formState, setFormState] = useState({
        patientId: patientDetails?.id || '', 
        patientName: patientDetails?.name || '',
        currentMedication: '',
        currentMedicationY: '',
        severityScore: '',
        onset: '',
        onsetOther: '',
        location: [],
        locationOther: '',
        bodySide: [],
        bodySideOther: '',
        presentingSymptoms: [],
        presentingSymptomsOther: '',
        healthHistory: [],
        allergies: [],
        redFlagSigns: [],
        redFlagSignsOther: '',
        additionalNotes: '',
    });

    const isValidText = text => typeof text === 'string' && text.trim() !== '';

    const hasSelection = (array) => {
        return array.length > 0;
    };

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;

        switch (type) {
            case "checkbox":
                const newArray = formState[name].includes(value)
                    ? formState[name].filter(item => item !== value)
                    : [...formState[name], value];
                setFormState(prevState => ({ ...prevState, [name]: newArray }));
                break;
            case "radio":
            case "select-one": // Handling select element changes
            case "text":
            case "textarea":
                setFormState(prevState => ({ ...prevState, [name]: value }));
                break;
            default:
                setFormState(prevState => ({ ...prevState, [name]: checked }));
                break;
        }
        onChange && onChange(formState);

        console.log(formState); // Note that this will log the state before it updates due to the asynchronous nature of setState
        return formState;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form Data:', formState);
      
        // Pass the collected data back to the parent component
        if (onSuccess) {
          onSuccess(formState);
        }
      };

    const nextStep = () => {
        setCurrentStep(currentStep < totalSteps ? currentStep + 1 : currentStep);
    };

    const prevStep = () => {
        setCurrentStep(currentStep > 1 ? currentStep - 1 : currentStep);
    };

    const isStepComplete = (step) => {
        switch (step) {
            case 1:
                return formState.currentMedication === 'No' || (formState.currentMedication === 'Yes' && isValidText(formState.currentMedicationY));
            case 2:
                return formState.healthHistory.length > 0;
            case 3:
                return formState.allergies.length > 0;
            case 4:
                return hasSelection(formState.redFlagSigns) && (formState.redFlagSigns.includes('OTHER') ? isValidText(formState.redFlagSignsOther) : true);
            case 5:
                return hasSelection(formState.presentingSymptoms) && (formState.presentingSymptoms.includes('OTHER') ? isValidText(formState.presentingSymptomsOther) : true);
            case 6:
                return formState.severityScore !== '';
            case 7:
                return hasSelection(formState.onset) && (formState.onset.includes('OTHER') ? isValidText(formState.onsetOther) : true);
            case 8:
                return hasSelection(formState.location) && (formState.location.includes('OTHER') ? isValidText(formState.locationOther) : true);
            case 9:
                return hasSelection(formState.bodySide) && (formState.bodySide.includes('OTHER') ? isValidText(formState.bodySideOther) : true);
            default:
                return false; // Ensure that there's always a condition to move forward
        }
    };

    const checkBoxField = (label, name, options, otherOptionName) => (
        <div>
            <label className="sticky-heading block text-3xl font-medium text-gray-700 mb-5">
                {label}
            </label>
            <ul className="px-0">
                {options.map(option => (
                    <li key={option.value}>
                        <input
                            type="checkbox"
                            id={`${name}-${option.value}`}
                            name={name}
                            value={option.value}
                            checked={formState[name].includes(option.value)}
                            onChange={handleInputChange}
                            className="checkbox-input"
                        />
                        <label htmlFor={`${name}-${option.value}`} className="checkbox-label text-sm">
                            {option.label}
                        </label>
                    </li>
                ))}
            </ul>
            {(formState[name].includes('OTHER') || formState[name].includes('TRAUMATIC_EVENT'))  && (
                <input
                    type="text"
                    name={otherOptionName}
                    value={formState[otherOptionName]}
                    onChange={handleInputChange}
                    placeholder="Please specify"
                    className="block mt-3 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-teal-500 focus:border-teal-500"
                />
            )}
        </div>
    );

    const renderStepContent = (step, formState, handleInputChange) => {
        switch (step) {
            case 1:
                return (
                    <div className="form-step">
                        <fieldset className="space-y-2">
                            <legend className="text-4xl font-medium text-gray-700 mb-24">Currently on medication?
                            </legend>
                            <ul className="grid w-full gap-7 md:grid-cols-2">
                                <li>
                                    <input
                                        type="radio"
                                        id="medication-yes"
                                        name="currentMedication"
                                        value="Yes"
                                        className="hidden peer"
                                        checked={formState.currentMedication === 'Yes'}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label htmlFor="medication-yes"
                                           className="flex items-center min-h-48 justify-between w-full p-4 text-left text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 peer-checked:border-teal-700 peer-checked:text-teal-700">
                                        <div className="block">
                                            <div className="text-3xl font-semibold">Yes</div>
                                            <div className="text-base">Specify medication below</div>
                                        </div>
                                        <svg className="ml-5 w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M9 5l7 7-7 7"/>
                                        </svg>
                                    </label>
                                </li>
                                <li>
                                    <input
                                        type="radio"
                                        id="medication-no"
                                        name="currentMedication"
                                        value="No"
                                        className="hidden peer"
                                        checked={formState.currentMedication === 'No'}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="medication-no"
                                           className="flex items-center h-48 justify-between w-full p-4 text-left text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 peer-checked:border-teal-700 peer-checked:text-teal-700">
                                        <div className="block">
                                            <div className="text-3xl font-semibold">No</div>
                                            <div className="text-base">Not currently using medication</div>
                                        </div>
                                        <svg className=" ml-5 w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M9 5l7 7-7 7"/>
                                        </svg>
                                    </label>
                                </li>
                            </ul>
                            {formState.currentMedication === 'Yes' && (
                                <input
                                    type="text"
                                    name="currentMedicationY"
                                    value={formState.currentMedicationY}
                                    onChange={handleInputChange}
                                    placeholder="Specify medication"
                                    className="mt-4 block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                                />
                            )}
                        </fieldset>
                    </div>

                )

            case 2:
                return (
                    <div className="form-step">
                        {/* Health History Field */}
                        {checkBoxField('Health History (Select all that apply)', 'healthHistory', [
                            {label: 'Age 12 and under', value: 'AGE_12_AND_UNDER'},
                            {label: 'Pregnancy', value: 'PREGNANCY'},
                            {label: 'Breastfeeding', value: 'BREASTFEEDING'},
                            {label: 'Cardiovascular Disease', value: 'CARDIOVASCULAR_DISEASE'},
                            {label: 'Gastrointestinal Disorder', value: 'GASTROINTESTINAL_DISORDER'},
                            {label: 'Renal Impairment', value: 'RENAL_IMPAIRMENT'},
                            {label: 'Bleeding Disorder', value: 'BLEEDING_DISORDER'},
                            {label: 'Hepatotoxicity Risk', value: 'HEPATOTOXICITY_RISK'},
                            {label: 'None of the above', value: 'NONE'}
                        ], 'healthHistoryOther')}
                    </div>
                )
            case 3:
                return (
                    <div className="form-step">
                        {/* Allergies Field */}
                        {checkBoxField('Allergies (Select all that apply)', 'allergies', [
                            {label: 'Pulmonary Condition (e.g., Asthma)', value: 'PULMONARY_CONDITION_EG_ASTHMA'},
                            {
                                label: 'Allergic to ASA/NSAID (e.g., Aspirin/Ibuprofen)',
                                value: 'ANAPHYLAXIS_URTICARIA_ANGIOEDEMA_ASA_NSAID'
                            },
                            {
                                label: 'Allergic to Acetaminophen (e.g., Tylenol)',
                                value: 'ANAPHYLAXIS_URTICARIA_ANGIOEDEMA_ACETAMINOPHEN'
                            },
                            {label: 'Not Applicable', value: 'NONE'}
                        ], 'allergiesOther')}
                    </div>
                );
            case 4:
                return (
                    <div className="form-step">
                        {/* Red Flag Signs Field */}
                        {checkBoxField('Do you experience any of the following', 'redFlagSigns', [
                            {label: 'Obvious Fracture', value: 'OBVIOUS_FRACTURE'},
                            {label: 'Joint Deformity', value: 'JOINT_DEFORMITY'},
                            {label: 'Functional Impairment', value: 'FUNCTIONAL_IMPAIRMENT'},
                            {label: 'Muscle Deformity', value: 'MUSCLE_DEFORMITY'},
                            {label: 'Muscle Weakness', value: 'MUSCLE_WEAKNESS'},
                            {label: 'Physical Traumatic Event', value: 'TRAUMATIC_EVENT'},
                            {label: 'Neurovascular Compromise', value: 'NEUROVASCULAR_COMPROMISE'},
                            {label: 'Increasing Pain', value: 'INCREASING_PAIN'},
                            {label: 'Persistent Pain', value: 'PERSISTENT_PAIN'},
                            {label: 'Head Trauma', value: 'HEAD_TRAUMA'},
                            { label: 'Eye Injury', value: 'EYE_INJURY' },
                            { label: 'Nosebleed', value: 'NOSEBLEED' },
                            { label: 'Perforated Eardrum', value: 'PERFORATED_EARDRUM' },
                            { label: 'Fever, Nausea, or vomiting', value: 'SYSTEMIC_SIGNS' },
                            { label: 'Abdominal Pain', value: 'ABDOMINAL_PAIN' },
                            { label: 'None', value: 'NONE' },
                            { label: 'Other', value: 'OTHER' }
                        ], 'redFlagSignsOther')}
                    </div>
                );
            case 5:
                return (
                    <div className="form-step">
                        {/* Presenting Symptoms Field */}
                        {checkBoxField('What brings you here today?', 'presentingSymptoms', [
                            { label: 'Pain at Rest', value: 'PAIN_AT_REST' },
                            { label: 'Pain on Movement', value: 'PAIN_ON_MOVEMENT' },
                            { label: 'Pain on Touch', value: 'PAIN_ON_PALPATION' },
                            { label: 'Swelling', value: 'SWELLING' },
                            { label: 'Bruising', value: 'BRUISING' },
                            { label: 'Muscle Spasm', value: 'MUSCLE_SPASM' },
                            { label: 'Muscle Weakness', value: 'MUSCLE_WEAKNESS' },
                            { label: 'Joint Instability', value: 'JOINT_INSTABILITY' },
                            { label: 'Physical Traumatic Event', value: 'TRAUMATIC_EVENT' },
                            { label: 'None', value: 'NONE' },
                            { label: 'Other', value: 'OTHER' }
                        ], 'presentingSymptomsOther')}
                    </div>
                );
            case 6:
                return (
                    <div className="form-step">
                        <div>
                            <label className="block text-3xl font-medium text-gray-700 mb-4">How Severe is the Pain?</label>
                            <select name="severityScore" value={formState.severityScore} onChange={handleInputChange}
                                    className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                                <option value="">Select Severity</option>
                                <option value="NO_PAIN">No Pain</option>
                                <option value="MILD">Mild</option>
                                <option value="MODERATE">Moderate</option>
                                <option value="SEVERE">Severe</option>
                            </select>
                        </div>
                    </div>
                );
            case 7:
                return (
                    <div className="form-step">
                        <div>
                            <label className="block text-3xl font-medium text-gray-700 mb-4">When did the Pain Start?</label>
                            <select name="onset" value={formState.onset} onChange={handleInputChange}
                                    className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                                <option value="">Select Onset</option>
                                <option value="LESS_THAN_48_HOURS">Less than 48 hours</option>
                                <option value="TWO_TO_14_DAYS">Two to 14 days</option>
                                <option value="MORE_THAN_14_DAYS">More than 14 days</option>
                                <option value="OTHER">Other</option>
                            </select>
                            {formState.onset === 'OTHER' && (
                                <input
                                    type="text"
                                    name="onsetOther"
                                    value={formState.onsetOther}
                                    onChange={handleInputChange}
                                    placeholder="Please specify"
                                    className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 mt-2"
                                />
                            )}
                        </div>
                    </div>
                );
            case 8:
                return (
                    <div className="form-step">
                        {/* Location Field */}
                        {checkBoxField('Pain Location', 'location', [
                            { label: 'Head', value: 'HEAD' },
                            { label: 'Neck', value: 'NECK' },
                            { label: 'Back', value: 'BACK' },
                            { label: 'Shoulder', value: 'SHOULDER' },
                            { label: 'Arm', value: 'ARM' },
                            { label: 'Hand', value: 'HAND' },
                            { label: 'Leg', value: 'LEG' },
                            { label: 'Foot', value: 'FOOT' },
                            { label: 'Other', value: 'OTHER' }
                        ], 'locationOther')}
                    </div>
                );
            case 9:
                return (
                    <div className="form-step">
                        {/* Body Side Field */}
                        {checkBoxField('Which side is the Pain', 'bodySide', [
                            {label: 'Left', value: 'LEFT'},
                            {label: 'Right', value: 'RIGHT'},
                            {label: 'Central', value: 'CENTRAL'},
                            {label: 'Other', value: 'OTHER'}
                        ], 'bodySideOther')}
                    <label className="block text-2xl font-medium text-gray-700 my-4">Anything you would like us to
                        know</label>
                    <textarea
                        name="additionalNotes"
                        value={formState.additionalNotes}
                        onChange={handleInputChange}
                        className="block mt-3 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        //dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                </div>
            )
            default:
                return null;
        }
    }
    return (
        <div className="form-wrapper md:mt-28 ">
            <form onSubmit={handleSubmit} className="form-container"
                  >
                <div className="steps-wrapper" style={{transform: `translateX(-${(currentStep - 1) * 100}%)`}}>
                {Array.from({length: totalSteps}).map((_, index) => (
                    <div className="form-step" key={index}>
                        {index + 1 === currentStep && (
                            <div className="step-content">
                                {/* Dynamically render form content based on the step */}
                                {renderStepContent(index + 1, formState, handleInputChange)}
                            </div>
                        )}
                    </div>
                ))}
                </div>
                <div className="navigation-buttons">
                    {currentStep > 1 && (
                        <button type="button" onClick={prevStep} className="back-button">Back</button>
                    )}
                    {currentStep < totalSteps && isStepComplete(currentStep) && (
                        <button type="button" onClick={nextStep} className="continue-button">Continue</button>
                    )}
                    {currentStep === totalSteps && isStepComplete(currentStep) && (
                        <button type="submit" className="submit-button">Submit</button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default PainAssessmentForm;
