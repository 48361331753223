export function AmberPage() {
    return (
        <div className="page amber my-5 sm:w-3/5 4/5 mx-auto text-center p-4" role="alert">
            <h1 className="text-3xl mb-5">We might be able to help you...</h1>
            <p className="mb-4 text-xl">Your case requires further investigation. We need to examine your file closely to ensure we provide the most suitable treatment.</p>
            <p className="font-light mb-4">We will email you within 24 hours with the appropriate treatment plan if one is available. In the meantime, you can proceed with payment to ensure that if you are eligible for a prescription, your medication will be shipped immediately after approval.</p>
            <div className="flex justify-center mt-4">
                <button className="bg-teal-700 text-white font-bold py-2 px-4 rounded m-2" onClick={() => window.location.href='/payment'}>Proceed to Payment</button>
                <button className="bg-gray-300 text-teal-700 font-bold py-2 px-4 rounded m-2" onClick={() => window.location.href='/'}>Return to Homepage</button>
            </div>
        </div>
    );
}
