import React, {useEffect} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DataStore } from "aws-amplify/datastore";
import { TreatmentPlan } from '../../models';

const Success = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get('session_id');

    useEffect(() => {
        const updateTreatmentPlanStatus = async () => {
            try {
                const response = await fetch(`http://localhost:4242/session-status?session_id=${sessionId}`);
                const { status, customer_email, metadata } = await response.json();

                if (status === 'complete') {
                    const treatmentPlanId = metadata.treatmentPlanId; // Retrieved from session metadata
                    const treatmentPlan = await DataStore.query(TreatmentPlan, treatmentPlanId);

                    if (treatmentPlan) {
                        await DataStore.save(
                            TreatmentPlan.copyOf(treatmentPlan, updated => {
                                updated.status = 'PAID';
                            })
                        );
                    }
                }
            } catch (error) {
                console.error('Failed to update treatment plan status:', error);
            }
        };

        updateTreatmentPlanStatus();
    }, [sessionId]);

    return (
        <div className="container mx-auto p-5 text-center">
            <h1 className="text-3xl font-bold text-teal-500">Payment Successful!</h1>
            <p className="text-xl mt-3">Thank you for your payment. Your transaction has been completed, and a receipt has been emailed to you. You may log into your account to view details of this transaction.</p>
            <button className="mt-4 bg-teal-700 hover:bg-teal-800 text-white font-bold py-2 px-4 rounded"
                    onClick={() => navigate('/dashboard')}>
                Go to Dashboard
            </button>
            <button className="mt-4 ml-2 bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
                    onClick={() => navigate('/contact')}>
                Contact Us
            </button>
        </div>
    );
};

export default Success;