import * as React from "react";
import '../../App.css';
import lab from "../../assets/lab.png";

function AboutSection() {
    return (
        <div className="px-5 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
                <div className="flex flex-col justify-center">
                    <div className="max-w-xl mb-6">
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                            Who
                            <br className="hidden md:block"/>
                            {/*We Are{' '}*/}
                            <span className="inline-block text-teal-700 text-5xl">
                                We Are
              </span>
                        </h2>
                        <p className="text-base text-gray-700 md:text-lg">
                            OuchRx is revolutionizing pain management through our innovative asynchronous telemedicine
                            platform.
                            Our mission is to provide personalized, effective, and convenient pain relief solutions to
                            our patients,
                            allowing them to access top-quality care from the comfort of their homes.
                        </p>
                        <p className="text-base text-gray-700 md:text-lg">
                            Our user-friendly web platform allows patients to easily fill out a detailed form outlining their symptoms
                                        and medical history. This information is reviewed by our healthcare professionals, who then provide a
                                        customized prescription based on the patient's specific needs. Once the prescription is issued, patients
                                      can order their medications directly through our platform, making pain management simple and hassle-free.
                        </p>
                        <p className="text-base text-gray-700 md:text-lg">
                            At OuchRx, we are committed to advancing the field of pain management and enhancing the
                            quality of life for
                            our patients. Our comprehensive approach, combining cutting-edge technology, personalized
                            care, and efficient
                            delivery, sets us apart as a leader in the industry. Join us on the path to a pain-free life
                            with OuchRx.
                        </p>
                    </div>
                    <div className="grid gap-8 row-gap-8 sm:grid-cols-2">
                        <div>
                            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
                                <img width="32" height="32"
                                     src="https://img.icons8.com/pastel-glyph/64/optical-microscope--v2.png"
                                     alt="optical-microscope--v2"/>
                            </div>
                            <h6 className="mb-2 font-semibold leading-5">
                                World-class Infrastructure
                            </h6>
                            <p className="text-sm text-gray-900">
                                At the core of OuchRx is our state-of-the-art compounding lab and pharmacy. We
                                specialize in creating
                                custom pain management formulations tailored to meet the unique needs of each patient.
                                Our team of
                                experienced pharmacists and technicians work diligently to ensure that every product
                                meets the highest
                                standards of quality and efficacy.
                            </p>
                        </div>
                        <div>
                            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
                                <img width="32" height="32" src="https://img.icons8.com/ios/50/in-transit--v1.png"
                                     alt="in-transit--v1"/>
                            </div>
                            <h6 className="mb-2 font-semibold leading-5">
                            Reliable Convenience
                            </h6>
                            <p className="text-sm text-gray-900">
                                In addition to our compounding capabilities, OuchRx boasts a comprehensive distribution
                                network that
                                ensures timely delivery of pain relief products directly to your doorstep. Whether
                                you're in need of
                                pain creams, gels, patches, or other medications, our streamlined process guarantees you
                                receive the
                                relief you need promptly and efficiently.
                            </p>
                        </div>

                    </div>
                </div>
                <div>
                    <img
                        className="object-cover w-full h-64 rounded shadow-lg sm:h-96"
                        // src="https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                        src={lab}
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
}

export default AboutSection;
