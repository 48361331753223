import React from 'react';
import { useNavigate } from 'react-router-dom';

const Cancel = () => {
    const navigate = useNavigate();

    return (
        <div className="container mx-auto p-5 text-center">
            <h1 className="text-3xl font-bold text-red-600">Payment Cancelled</h1>
            <p className="text-xl mt-3">Your payment process was not completed. If this was an error, you can attempt to make a payment again. If you continue to experience issues, please contact our support team.</p>
            <button className="mt-4 bg-teal-700 hover:bg-teal-800 text-white font-bold py-2 px-4 rounded"
                    onClick={() => navigate('/payment')}>
                Try Again
            </button>
            <button className="mt-4 ml-2 bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
                    onClick={() => navigate('/contact')}>
                Get Help
            </button>
        </div>
    );
};

export default Cancel;
