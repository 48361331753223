import React from 'react';

const NotFound = () => {
    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-teal-600 dark:text-teal-700">404</h1>
                    <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-grey-700">Something's
                        missing.</p>
                    <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                        {/*Sorry, we can't find that page.*/}
                        You've wandered too far.
                         You'll find lots to explore on the home page. </p>
                    <a href="/"
                       className="inline-flex text-white bg-teal-600 hover:bg-teal-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-extrabold rounded text-sm px-4 py-2.5 text-center dark:focus:ring-primary-900 my-4">Back
                        to Homepage</a>
                </div>
            </div>
        </section>
    );
};

export default NotFound;
