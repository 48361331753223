import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import {
  
  PainAssessment,
  TreatmentPlan,
  Patient,
} from "../../models";
import { useAuthenticator } from "@aws-amplify/ui-react";

const STATUS_OPTIONS = {
  SEE_DOCTOR: "SEE_DOCTOR",
  PENDING_REVIEW: "PENDING_REVIEW",
  UNPAID: "UNPAID",
  PAID: "PAID",
  SHIPPED: "SHIPPED",
};

const Admin = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedRows, setExpandedRows] = useState({});
  const [painAssessments, setPainAssessments] = useState([]);
  const [filteredPainAssessments, setFilteredPainAssessments] = useState([]);

  // Helper to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  // Calculate total pages
  const pageCount = Math.ceil(filteredPainAssessments.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredPainAssessments.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const getStatusLabel = (status) => {
    const statusStyles = {
      SEE_DOCTOR: {
        bgColor: "bg-red-100 dark:bg-red-600",
        textColor: "text-white",
        icon: (
          <svg
            className="mr-1 h-3 w-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ),
      },
      PENDING_REVIEW: {
        bgColor: "bg-yellow-100 dark:bg-yellow-400",
        textColor: "text-white",
        icon: (
          <svg
            className="mr-1 h-3 w-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 8v4l3 3M12 2a9 9 0 100 18 9 9 0 000-18z"
            />
          </svg>
        ),
      },
      PAID: {
        bgColor: "bg-green-100 dark:bg-green-500",
        textColor: "text-white",
        icon: (
          <svg
            className="mr-1 h-3 w-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 11.917 9.724 16.5 19 7.5"
            />
          </svg>
        ),
      },
      SHIPPED: {
        bgColor: "bg-blue-100 dark:bg-blue-700",
        textColor: "text-white",
        icon: (
          <svg
            className="mr-1 h-3 w-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 7h6l2 4m-8-4v8m0-8V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v9h2m8 0H9m4 0h2m4 0h2v-4m0 0h-5m3.5 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-10 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
            />
          </svg>
        ),
      },
      UNPAID: {
        bgColor: "bg-orange-700 dark:bg-orange-500",
        textColor: "text-white",
        icon: (
          <svg
            className="mr-1 h-3 w-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            {/*<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.5 4h-13m13 16h-13M8 20v-3.333a2 2 0 0 1 .4-1.2L10 12.6a1 1 0 0 0 0-1.2L8.4 8.533a2 2 0 0 1-.4-1.2V4h8v3.333a2 2 0 0 1-.4 1.2L13.957 11.4a1 1 0 0 0 0 1.2l1.643 2.867a2 2 0 0 1 .4 1.2V20H8Z" />*/}

            {/*<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
            {/*      d="M3 5h18a1 1 0 011 1v12a1 1 0 01-1 1H3a1 1 0 01-1-1V6a1 1 0 011-1zm0 3h18v8H3V8z"/>*/}
            {/*<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 9h4v6H7z"/>*/}
            <rect x="2" y="3" width="20" height="18" rx="2" ry="2"></rect>
            <line x1="2" y1="7" x2="22" y2="7"></line>
            <line x1="6" y1="11" x2="9" y2="11"></line>
            <line x1="6" y1="15" x2="9" y2="15"></line>
            <line x1="6" y1="19" x2="9" y2="19"></line>
            <line x1="13" y1="11" x2="18" y2="11"></line>
            <line x1="13" y1="15" x2="18" y2="15"></line>
            <line x1="13" y1="19" x2="18" y2="19"></line>
          </svg>
        ),
      },
    };

    const defaultStyle = {
      bgColor: "bg-gray-100 dark:bg-gray-700",
      textColor: "text-gray-800 dark:text-gray-300",
      icon: null,
    };
    const { bgColor, textColor, icon } = statusStyles[status] || defaultStyle;
    return (
      <span
        className={`inline-flex items-center rounded px-2.5 py-0.5 ${bgColor} ${textColor}`}
      >
        {icon}
        {formatField(status)}
      </span>
    );
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const maxPageNumberLimit = 5;
    const minPageNumberLimit = 0;

    for (let i = 1; i <= pageCount; i++) {
      if (i > minPageNumberLimit && i < maxPageNumberLimit + 1) {
        pageNumbers.push(i);
      }
    }

    return (
      <nav
        className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
        aria-label="Table navigation"
      >
        <span className="text-sm font-normal text-gray-400 dark:text-gray-500">
          Showing
          <span className="font-semibold mx-1 text-gray-700 dark:text-gray-700">
            {indexOfFirstItem + 1}-{indexOfLastItem}
          </span>
          of
          <span className="font-semibold mx-1 text-gray-700 dark:text-gray-700">
            {filteredPainAssessments.length}
          </span>
        </span>
        <ul className="inline-flex items-stretch -space-x-px">
          {currentPage > 1 && (
            <li>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-400 bg-gray-800 rounded-l-lg border border-gray-700 hover:bg-gray-700 hover:text-white dark:text-gray-500 dark:bg-white dark:border-gray-300 dark:hover:bg-gray-100 dark:hover:text-gray-700"
              >
                <span className="sr-only">Previous</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </li>
          )}
          {pageNumbers.map((number) => (
            <li key={number}>
              <button
                onClick={() => handlePageChange(number)}
                className={`flex items-center justify-center text-sm py-2 px-3 leading-tight ${
                  currentPage === number
                    ? "text-primary-700 bg-primary-100 border-primary-300 hover:bg-primary-50 hover:text-primary-600"
                    : "text-gray-400 bg-gray-800 border-gray-700 hover:bg-gray-700 hover:text-white"
                } dark:text-gray-500 dark:bg-white dark:border-gray-300 dark:hover:bg-gray-100 dark:hover:text-gray-700`}
              >
                {number}
              </button>
            </li>
          ))}
          {currentPage < pageCount && (
            <li>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-400 bg-gray-800 rounded-r-lg border border-gray-700 hover:bg-gray-700 hover:text-white dark:text-gray-500 dark:bg-white dark:border-gray-300 dark:hover:bg-gray-100 dark:hover:text-gray-700"
              >
                <span className="sr-only">Next</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </li>
          )}
        </ul>
      </nav>
    );
  };

  function formatField(input) {
    // Split the string on underscores, capitalize the first letter of each part, and join them with a space
    return input
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter
      .join(" "); // Join with space
  }

  const toggleDetails = (index) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const fetchPainAssessments = async () => {
    try {
      const painAssessmentsData = await DataStore.query(PainAssessment);
      const assessmentsWithDetails = await Promise.all(
        painAssessmentsData.map(async (assessment) => {
          const patient = await DataStore.query(Patient, assessment.patientId);
          const treatmentPlans = await DataStore.query(TreatmentPlan, (c) =>
            c.painAssessmentId.eq(assessment.id)
          );
          return {
            ...assessment,
            patient,
            treatmentPlans,
          };
        })
      );
      console.log("Pain Assessments with details:", assessmentsWithDetails);
      setPainAssessments(assessmentsWithDetails);
      setFilteredPainAssessments(assessmentsWithDetails);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching pain assessments:", error);
      setError("Failed to fetch pain assessments: " + error.message);
      setLoading(false);
    }
  };

  // Fetch pain assessments with associated Patient and TreatmentPlan
  useEffect(() => {
    fetchPainAssessments();
  }, []);

  // Filter pain assessments based on status
  useEffect(() => {
    const filtered =
      statusFilter === "All"
        ? painAssessments
        : painAssessments.filter((assessment) =>
            assessment.treatmentPlans.some(
              (plan) => plan.status === statusFilter
            )
          );
    setFilteredPainAssessments(filtered);
  }, [statusFilter, painAssessments]);

  // Search functionality
  const filterPainAssessments = (data) => {
    if (!searchTerm) return data; // Return all if no search term
    return data.filter((item) =>
      item.patient?.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  // Update filtered assessments whenever searchTerm changes
  useEffect(() => {
    const filteredData = filterPainAssessments(painAssessments);
    setFilteredPainAssessments(filteredData);
  }, [painAssessments, searchTerm]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const handleStatusChange = (index, newStatus) => {
    setFilteredPainAssessments((prevState) => {
      const updatedAssessments = [...prevState];
      const updatedTreatmentPlans = [
        ...updatedAssessments[index].treatmentPlans,
      ];
      updatedTreatmentPlans[0] = {
        ...updatedTreatmentPlans[0],
        status: newStatus,
      };
      updatedAssessments[index].treatmentPlans = updatedTreatmentPlans;
      return updatedAssessments;
    });
  };

  const updateTreatmentStatus = async (id, newStatus) => {
    try {
      const treatmentPlanToUpdate = await DataStore.query(TreatmentPlan, id);
      await DataStore.save(
        TreatmentPlan.copyOf(treatmentPlanToUpdate, (updated) => {
          updated.status = newStatus;
        })
      );
      alert("Status updated successfully!");
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleCommentChange = (index, newComment) => {
    setFilteredPainAssessments((prevState) => {
      const updatedAssessments = [...prevState]; // Copy the state array
      const updatedTreatmentPlans = [
        ...updatedAssessments[index].treatmentPlans,
      ]; // Copy treatmentPlans
      updatedTreatmentPlans[0] = {
        ...updatedTreatmentPlans[0], // Copy the first treatmentPlan
        additionalComments: newComment, // Set the new comment
      };

      updatedAssessments[index].treatmentPlans = updatedTreatmentPlans; // Update the treatmentPlans for this assessment
      return updatedAssessments;
    });
  };

  const saveComments = async (id, updatedComment) => {
    try {
      const treatmentPlanToUpdate = await DataStore.query(TreatmentPlan, id);
      await DataStore.save(
        TreatmentPlan.copyOf(treatmentPlanToUpdate, (updated) => {
          updated.additionalComments = updatedComment; // Set the updated comment
        })
      );
      alert("Comments updated successfully!");
    } catch (error) {
      console.error("Error saving comments:", error);
    }
  };

  return (
    <div>
      <h1 className="p-5 ">Welcome Admin</h1>
      {/*<h2 className="px-5 ">Welcome, {user.signInDetails.loginId}!</h2>*/}
      <section className="container px-4 my-16 mx-auto">
        <div className="flex flex-col">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
                <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                  <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                    <form className="flex items-center w-full md:w-1/2">
                      <label form="simple-search" className="sr-only">
                        Search
                      </label>
                      <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </div>
                        <input
                          type="text"
                          id="simple-search"
                          className="bg-gray-700 border border-gray-600 text-black text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5 dark:bg-gray-50 dark:border-gray-300 dark:text-gray-900 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Search Patients"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                    </form>
                    <div className="relative">
                      <button
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                        className="inline-flex justify-center w-full rounded-md border shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          className="h-4 w-4 mr-2 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Filter
                        <svg
                          className="ml-1.5 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: 0,
                          transform: "translateX(0%)",
                          width: "100%",
                          maxHeight: "125px",
                          overflow: "auto",
                        }}
                        className={`${
                          dropdownOpen ? "block" : "hidden"
                        } origin-top-right absolute mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none`}
                      >
                        <ul className="py-1 text-sm text-gray-700 dark:text-gray-700">
                          <li>
                            <button
                              onClick={() => setStatusFilter("All")}
                              className="block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              All
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => setStatusFilter("SEE_DOCTOR")}
                              className="block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              See Doctor
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => setStatusFilter("PENDING_REVIEW")}
                              className="block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              Pending Review
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => setStatusFilter("UNPAID")}
                              className="block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              Unpaid
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => setStatusFilter("PAID")}
                              className="block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              Paid
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => setStatusFilter("SHIPPED")}
                              className="block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                              Shipped
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-teal-700 ">
                      <tr>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-lg font-bold text-left rtl:text-right text-white"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-lg font-bold text-left rtl:text-right text-white"
                        >
                          Patient
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-lg font-bold text-left rtl:text-right text-white"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-lg font-bold text-left rtl:text-right text-white"
                        >
                          Presenting Symptoms
                        </th>
                        <th scope="col" className="relative py-3.5 px-4">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="bg-white divide-y divide-gray-300">
  {filteredPainAssessments.length > 0 ? (
    filteredPainAssessments.map((item, index) => (
      <React.Fragment key={index}>
        <tr>
          <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
            {item.treatmentPlans[0].createdAt
              ? formatDate(item.treatmentPlans[0].createdAt)
              : 'No date'}
          </td>
          <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
            {formatField(item.patient.name)
              .split(' ')
              .map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(' ')}
          </td>
          <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
            {item.treatmentPlans && item.treatmentPlans[0]
              ? getStatusLabel(item.treatmentPlans[0].status)
              : 'No Status'}
          </td>
          <td className="px-4 py-4 text-base text-gray-600 whitespace-nowrap">
            {item.presentingSymptoms && item.presentingSymptoms.length > 0
              ? formatField(item.presentingSymptoms[0])
              : 'No Symptoms Reported'}
          </td>
          <td className="py-4 flex justify-end px-4 text-base whitespace-nowrap">
            <button
              onClick={() => toggleDetails(index)}
              className="text-teal-700 transition-colors duration-200 hover:text-teal-900 focus:outline-none"
            >
              {expandedRows[index] ? 'Collapse' : 'View'}
            </button>
          </td>
        </tr>
        {/* Always render the details row */}
        <tr>
          <td colSpan="5" className="p-0">
            <div
              className={`overflow-hidden transition-all duration-500 ${
                expandedRows[index]
                  ? 'max-h-[2000px] opacity-100'
                  : 'max-h-0 opacity-0'
              }`}
            >
              <div className="p-4 bg-gray-100">
                <h3 className="text-lg font-semibold">
                  Patient and Pain Assessment Details
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-sm">
                  {/* Patient Information */}
                  <div>
                    <h4 className="font-semibold">Patient Information</h4>
                    <p>
                      <strong>Name:</strong>{' '}
                      {formatField(item.patient.name)
                        .split(' ')
                        .map(
                          (word) =>
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase()
                        )
                        .join(' ')}
                    </p>
                    <p>
                      <strong>Email:</strong> {item.patient.email}
                    </p>
                    <p>
                      <strong>Phone:</strong> {item.patient.phone}
                    </p>
                    <p>
                      <strong>Address:</strong> {item.patient.address}
                    </p>
                    <p>
                      <strong>Date of Birth:</strong>{' '}
                      {formatDate(item.patient.dateOfBirth)}
                    </p>
                    <p>
                      <strong>Sex:</strong>{' '}
                      {item.patient.sex
                        ? item.patient.sex
                            .split(' ')
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(' ')
                        : 'Not Specified'}
                    </p>
                  </div>

                  {/* Pain Assessment */}
                  <div>
                    <h4 className="font-semibold">Pain Assessment</h4>
                    <p>
                      <strong>Assessment Date:</strong>{' '}
                      {item.treatmentPlans &&
                      item.treatmentPlans[0]?.createdAt
                        ? formatDate(item.treatmentPlans[0].createdAt)
                        : 'No Date'}
                    </p>
                    <p>
                      <strong>Current Medication:</strong>{' '}
                      {item.currentMedication}
                    </p>
                    {item.currentMedication === 'Yes' && (
                      <p>
                        <strong>Current Medication Details:</strong>{' '}
                        {item.currentMedicationY}
                      </p>
                    )}
                    <p>
                      <strong>Severity Score:</strong>{' '}
                      {item.severityScore
                        ? formatField(item.severityScore)
                        : 'Not Specified'}
                    </p>
                    <p>
                      <strong>Onset:</strong>{' '}
                      {item.onset
                        ? formatField(item.onset)
                        : 'Custom Duration Reported'}
                    </p>
                    {item.onsetOther && (
                      <p>
                        <strong>Onset Other:</strong> {item.onsetOther}
                      </p>
                    )}
                    <p>
                      <strong>Location:</strong>{' '}
                      {item.location
                        ? formatField(item.location.join(', '))
                        : 'No Location Reported'}
                    </p>
                    {item.locationOther && (
                      <p>
                        <strong>Location Other:</strong> {item.locationOther}
                      </p>
                    )}
                    <p>
                      <strong>Body Side:</strong>{' '}
                      {item.bodySide
                        ? formatField(item.bodySide.join(', '))
                        : 'No Body Side Reported'}
                    </p>
                    {item.bodySideOther && (
                      <p>
                        <strong>Body Side Other:</strong> {item.bodySideOther}
                      </p>
                    )}
                    <p>
                      <strong>Symptoms:</strong>{' '}
                      {item.presentingSymptoms
                        ? formatField(item.presentingSymptoms.join(', '))
                        : 'No Symptoms Reported'}
                    </p>
                    {item.presentingSymptomsOther && (
                      <p>
                        <strong>Symptoms Other:</strong>{' '}
                        {item.presentingSymptomsOther}
                      </p>
                    )}
                    <p>
                      <strong>Allergies:</strong>{' '}
                      {item.allergies
                        ? formatField(item.allergies.join(', '))
                        : 'No Allergies Reported'}
                    </p>
                    <p>
                      <strong>Health History:</strong>{' '}
                      {item.healthHistory
                        ? formatField(item.healthHistory.join(', '))
                        : 'No Health History Reported'}
                    </p>
                    <p>
                      <strong>Red Flag Signs:</strong>{' '}
                      {item.redFlagSigns
                        ? formatField(item.redFlagSigns.join(', '))
                        : 'No Red Flag Signs'}
                    </p>
                    {item.redFlagSignsOther && (
                      <p>
                        <strong>Red Flag Signs Other:</strong>{' '}
                        {item.redFlagSignsOther}
                      </p>
                    )}
                    {item.traumaticOther && (
                      <p>
                        <strong>Traumatic Other:</strong>{' '}
                        {item.traumaticOther}
                      </p>
                    )}
                    {item.additionalNotes && (
                      <p>
                        <strong>Additional Notes:</strong>{' '}
                        {item.additionalNotes}
                      </p>
                    )}
                  </div>

                  {/* Treatment Plan */}
                  <div>
                    <h4 className="font-semibold">Treatment Plan</h4>
                    <p>
                      <strong>Status:</strong>{' '}
                      {item.treatmentPlans && item.treatmentPlans[0]
                        ? getStatusLabel(item.treatmentPlans[0].status)
                        : 'No Status'}
                    </p>
                    <p>
                      <strong>Recommendation:</strong>{' '}
                      {(item.treatmentPlans &&
                        item.treatmentPlans[0]?.recommendedTreatment) ||
                        'N/A'}
                    </p>

                    {/* Approve/Deny buttons for PENDING_REVIEW status */}
                    {item.treatmentPlans &&
                      item.treatmentPlans[0]?.status === 'PENDING_REVIEW' && (
                        <div className="mb-4">
                          <button
                            onClick={() =>
                              updateTreatmentStatus(
                                item.treatmentPlans[0].id,
                                'UNPAID'
                              )
                            }
                            className="bg-green-500 text-white py-1 px-3 rounded mr-2"
                          >
                            Approve
                          </button>
                          <button
                            onClick={() =>
                              updateTreatmentStatus(
                                item.treatmentPlans[0].id,
                                'SEE_DOCTOR'
                              )
                            }
                            className="bg-red-500 text-white py-1 px-3 rounded"
                          >
                            Deny
                          </button>
                        </div>
                      )}

                    {/* Comments input and Save button */}
                    <p>
                      <strong>Comments:</strong>
                      <input
                        type="text"
                        value={
                          item.treatmentPlans[0].additionalComments || ''
                        }
                        onChange={(e) =>
                          handleCommentChange(index, e.target.value)
                        }
                        className="ml-2 border rounded p-1"
                      />
                      <button
                        onClick={() =>
                          saveComments(
                            item.treatmentPlans[0].id,
                            item.treatmentPlans[0].additionalComments
                          )
                        }
                        className="ml-2 bg-blue-500 text-white py-1 px-2 rounded"
                      >
                        Save
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </React.Fragment>
    ))
  ) : (
    <tr>
      <td colSpan="5" className="text-center py-4">
        No Pain Assessments found.
      </td>
    </tr>
  )}
</tbody>

                  </table>
                  {renderPagination()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Admin;
