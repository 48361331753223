import React, {useEffect, useState} from 'react';
import {CartItem, Cart} from "../../models";
import {DataStore} from "aws-amplify/datastore";
import {useAuthenticator} from "@aws-amplify/ui-react";

const products = [
    {
        id: 1,
        name: 'Voltaren Emulgel',
        imageUrl: 'https://i5.walmartimages.ca/images/Enlarge/690/139/6000207690139.jpg',
        basePrice: 29.99,
        strengths: {
            '50 mg': 1.0,
            '100 mg': 1.2
        },
        frequencies: {
            'Once': 1,
            'Monthly': 0.8,  // 20% discount
            'Quarterly': 0.9 // 10% discount
        }
    },
    {
        id: 2,
        name: 'Ibuprofen Cream',
        imageUrl: 'https://digitalcontent.api.tesco.com/v2/media/ghs/3c83e0e0-9021-48dd-88fd-b1888ce4cf04/c6a4f4ad-b3ee-4325-968d-f3a24be49849_425869089.jpeg',
        basePrice: 19.00,
        strengths: {
            '200 mg': 1.0,
            '400 mg': 1.2
        },
        frequencies: {
            'Once': 1,
            'Monthly': 0.9,  // 10% discount
            'Quarterly': 0.8 // 20% discount
        }
    },
    {
        id: 3,
        name: 'Capsaicin Heat Patch',
        imageUrl: 'https://www.meijer.com/content/dam/meijer/product/0071/92/8350/12/0071928350127_1_A1C1_1200.png',
        basePrice: 15.99,
        strengths: {
            '0.025%': 1.0,
            '0.075%': 1.2
        },
        frequencies: {
            'Once': 1,
            'Monthly': 0.9,  // 10% discount
            'Quarterly': 0.8 // 20% discount
        }
    },
    {
        id: 4,
        name: 'Aspirin Pain Relief',
        imageUrl: 'https://voila.ca/images-v3/2d92d19c-0354-49c0-8a91-5260ed0bf531/299a3d4e-1ca0-4489-af8d-532f8da744eb/500x500.jpg',
        basePrice: 12.00,
        strengths: {
            '81 mg': 1.0,
            '325 mg': 1.2
        },
        frequencies: {
            'Once': 1,
            'Monthly': 0.9,  // 10% discount
            'Quarterly': 0.8 // 20% discount
        }
    }
];

const Storefront = () => {
    const [selections, setSelections] = useState(products.map(product => ({
        productId: product.id,
        strength: Object.keys(product.strengths)[0],
        frequency: Object.keys(product.frequencies)[0]
    })));
    const { user} = useAuthenticator((context) => [context.user]);
    const [cart, setCart] = useState(null);

    // useEffect(() => {
    //     const fetchCart = async () => {
    //         if (!user) return;
    //         console.log('user:', user);
    //         const userCart = await DataStore.query(Cart, c => c.userId.eq(user.userId));
    //         if (!userCart) {
    //             // Create a new cart if one doesn't exist
    //             const newCart = await DataStore.save(new Cart({
    //                 userId: user.userId,
    //                 items: []
    //             }));
    //             setCart(newCart);
    //             console.log('New cart created:', newCart);
    //         } else {
    //             // const newCart = await DataStore.save(new Cart({
    //             //     userId: user.userId,
    //             //     items: []
    //             // }));
    //             setCart(userCart);
    //             // setCart(newCart);
    //             console.log('Existing cart found:', userCart);
    //         }
    //     };
    //
    //     fetchCart();
    // }, [user]);

    useEffect(() => {
        const fetchCart = async () => {
            if (!user) return;
            console.log('user:', user);
            const userCarts = await DataStore.query(Cart, c => c.userId.eq(user.userId));
            if (userCarts.length === 0) {
                // Create a new cart if one doesn't exist
                const newCart = await DataStore.save(new Cart({
                    userId: user.userId,
                    items: []
                }));
                setCart(newCart);
                console.log('New cart created:', newCart);
            } else {
                setCart(userCarts[0]);  // Assuming the first cart is the correct one
                console.log('Existing cart found:', userCarts[0]);
            }
        };

        fetchCart();
    }, [user]);

    const handleSelectionChange = (productId, type, value) => {
        setSelections(selections.map(selection => {
            if (selection.productId === productId) {
                return {
                    ...selection,
                    [type]: value
                };
            }
            return selection;
        }));
        document.querySelectorAll(`details[open]`).forEach(detail => {
            if (detail.querySelector(`select[name="${type}"]`)) {
                detail.removeAttribute('open');
            }
        });
    };

    const addToCart = async (product, quantity = 1) => {
        if (!cart) {
            console.log('No cart available');
            return;  // Optionally handle the error more gracefully
        }

        // Fetch the cart item if it exists within the current cart
        console.log('cart:', cart.id, 'product:', product.id);
        const existingItem = await DataStore.query(CartItem, ci =>
            ci.cartId.eq(cart.id).medicationId.eq(product.id)
        );

        // If item exists, update the quantity, otherwise create a new cart item
        if (existingItem) {
            await DataStore.save(CartItem.copyOf(existingItem, updated => {
                updated.quantity += quantity;
            }));
        } else {
            await DataStore.save(new CartItem({
                cartId: cart.id,
                medicationId: product.id,
                price: calculatePrice(product.id),  // Ensure this function is defined to calculate the price
                quantity: quantity
            }));
        }

        console.log("Product added to cart:", product.name);
    };

    const calculatePrice = (productId) => {
        const selection = selections.find(sel => sel.productId === productId);
        const product = products.find(p => p.id === productId);
        return (product.basePrice * product.strengths[selection.strength] * product.frequencies[selection.frequency]).toFixed(2);
    };

    return (
        <section>
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
                <header>
                    <h2 className="text-xl font-bold text-teal-800 sm:text-3xl">Our Medication Collection</h2>
                    <p className="mt-4 max-w-md text-gray-500">
                        Explore our top-rated medications for effective pain management and wellness enhancement.
                    </p>
                </header>

                <div className="mt-8 sm:flex sm:items-center sm:justify-between">
                    <div className="block sm:hidden">
                        <button
                            className="flex cursor-pointer items-center gap-2 border-b border-gray-400 pb-1 text-gray-900 transition hover:border-gray-600"
                        >
                            <span className="text-sm font-medium"> Filters & Sorting </span>

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="size-4 rtl:rotate-180"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"/>
                            </svg>
                        </button>
                    </div>

                    <div className="hidden sm:flex sm:gap-4">
                        <div className="relative">
                            <details className="group [&_summary::-webkit-details-marker]:hidden">
                                <summary
                                    className="flex cursor-pointer items-center gap-2 border-b border-gray-400 pb-1 text-gray-900 transition hover:border-gray-600"
                                >
                                    <span className="text-sm font-medium"> Availability </span>

                                    <span className="transition group-open:-rotate-180">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-4 w-4"
                    >
                      <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                                </summary>
                                <div
                                    className="z-50 group-open:absolute group-open:top-auto group-open:mt-2 ltr:group-open:start-0"
                                >
                                    <div className="w-96 rounded border border-gray-200 bg-white">
                                        <header className="flex items-center justify-between p-4">
                                            <span className="text-sm text-gray-700"> 0 Selected </span>

                                            <button type="button"
                                                    className="text-sm text-gray-900 underline underline-offset-4">
                                                Reset
                                            </button>
                                        </header>

                                        <ul className="space-y-1 border-t border-gray-200 p-4">
                                            <li>
                                                <label htmlFor="FilterInStock"
                                                       className="inline-flex items-center gap-2">
                                                    <input
                                                        type="checkbox"
                                                        id="FilterInStock"
                                                        className="size-5 rounded border-gray-300"
                                                    />

                                                    <span
                                                        className="text-sm font-medium text-gray-700"> In Stock (5+) </span>
                                                </label>
                                            </li>

                                            <li>
                                                <label htmlFor="FilterPreOrder"
                                                       className="inline-flex items-center gap-2">
                                                    <input
                                                        type="checkbox"
                                                        id="FilterPreOrder"
                                                        className="size-5 rounded border-gray-300"
                                                    />

                                                    <span
                                                        className="text-sm font-medium text-gray-700"> Pre Order (3+) </span>
                                                </label>
                                            </li>

                                            <li>
                                                <label htmlFor="FilterOutOfStock"
                                                       className="inline-flex items-center gap-2">
                                                    <input
                                                        type="checkbox"
                                                        id="FilterOutOfStock"
                                                        className="size-5 rounded border-gray-300"
                                                    />

                                                    <span className="text-sm font-medium text-gray-700"> Out of Stock (10+) </span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </details>
                        </div>

                        <div className="relative">
                            <details className="group [&_summary::-webkit-details-marker]:hidden">
                                <summary
                                    className="flex cursor-pointer items-center gap-2 border-b border-gray-400 pb-1 text-gray-900 transition hover:border-gray-600"
                                >
                                    <span className="text-sm font-medium"> Price </span>

                                    <span className="transition group-open:-rotate-180">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-4 w-4"
                    >
                      <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                </summary>
                <div
                    className="z-50 group-open:absolute group-open:top-auto group-open:mt-2 ltr:group-open:start-0"
                >
                    <div className="w-96 rounded border border-gray-200 bg-white">
                        <header className="flex items-center justify-between p-4">
                            <span className="text-sm text-gray-700"> The highest price is $600 </span>

                            <button type="button"
                                    className="text-sm text-gray-900 underline underline-offset-4">
                                Reset
                            </button>
                        </header>

                        <div className="border-t border-gray-200 p-4">
                            <div className="flex justify-between gap-4">
                                <label htmlFor="FilterPriceFrom" className="flex items-center gap-2">
                                    <span className="text-sm text-gray-600">$</span>

                                    <input
                                        type="number"
                                        id="FilterPriceFrom"
                                        placeholder="From"
                                        className="w-full rounded-md border-gray-200 shadow-sm sm:text-sm"
                                    />
                                </label>

                                <label htmlFor="FilterPriceTo" className="flex items-center gap-2">
                                    <span className="text-sm text-gray-600">$</span>

                                    <input
                                        type="number"
                                        id="FilterPriceTo"
                                        placeholder="To"
                                        className="w-full rounded-md border-gray-200 shadow-sm sm:text-sm"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </details>
        </div>
    </div>

    <div className="hidden sm:block">
        <label htmlFor="SortBy" className="sr-only">SortBy</label>

        <select id="SortBy" className="h-10 rounded border-gray-300 text-sm">
            <option>Sort By</option>
            <option value="Title, DESC">Title, DESC</option>
            <option value="Title, ASC">Title, ASC</option>
            <option value="Price, DESC">Price, DESC</option>
            <option value="Price, ASC">Price, ASC</option>
        </select>
    </div>
    </div>
            <ul className="mt-4 grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
                {products.map(product => (
                    <li key={product.id} className="group block overflow-hidden">
                        <img
                            src={product.imageUrl}
                            alt={product.name}
                            className="h-[350px] w-full object-cover transition duration-500 group-hover:scale-105 sm:h-[450px]"
                        />
                        <div className="relative bg-white pt-3">
                            <h3 className="text-xs text-gray-700 group-hover:underline group-hover:underline-offset-4">
                                {product.name}
                            </h3>

                            <div className="flex flex-col">

                                {/* Strength Selector */}
                                <details className="group [&_summary::-webkit-details-marker]:hidden">
                                    <summary
                                        className="flex cursor-pointer items-center gap-2 border-b border-gray-400 pb-1 text-gray-900 transition hover:border-gray-600">
                                        <span className="text-sm font-medium">Strength</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="1.5" stroke="currentColor" className="h-4 w-4">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                                        </svg>
                                    </summary>
                                    <select
                                        className="block w-full border-0"
                                        value={selections.find(sel => sel.productId === product.id).strength}
                                        onChange={(e) => {
                                            handleSelectionChange(product.id, 'strength', e.target.value);
                                            e.target.closest('details').removeAttribute('open'); // Close the dropdown
                                        }}
                                    >
                                        {Object.keys(product.strengths).map(strength => (
                                            <option key={strength} value={strength}>{strength}</option>
                                        ))}
                                    </select>
                                </details>


                                {/* Frequency Selector */}
                                <details className="group [&_summary::-webkit-details-marker]:hidden">
                                    <summary
                                        className="flex cursor-pointer items-center gap-2 border-b border-gray-400 pb-1 text-gray-900 transition hover:border-gray-600">
                                        <span className="text-sm font-medium">Frequency</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="1.5" stroke="currentColor" className="h-4 w-4">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                                        </svg>
                                    </summary>
                                    <select
                                        className="block w-full border-0"
                                        value={selections.find(sel => sel.productId === product.id).frequency}
                                        onChange={(e) => {
                                            handleSelectionChange(product.id, 'frequency', e.target.value);
                                            e.target.closest('details').removeAttribute('open'); // Close the dropdown
                                        }}
                                    >
                                        {Object.keys(product.frequencies).map(frequency => (
                                            <option key={frequency} value={frequency}>{frequency}</option>
                                        ))}
                                    </select>
                                </details>


                                <p className="mt-2 tracking-wider text-gray-900">
                                    ${calculatePrice(product.id)}
                                </p>

                                <button
                                    onClick={() => addToCart(product)}
                                    className="mt-2 bg-teal-600 hover:bg-teal-700 text-white font-medium py-2 px-4 rounded"
                                >
                                    Add to Cart
                                </button>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            </div>
        </section>
    );
};

export default Storefront;
