// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const AreaOfPain = {
  "HEAD": "HEAD",
  "NECK": "NECK",
  "BACK": "BACK",
  "SHOULDER": "SHOULDER",
  "ARM": "ARM",
  "HAND": "HAND",
  "LEG": "LEG",
  "FOOT": "FOOT",
  "OTHER": "OTHER"
};

const Onset = {
  "LESS_THAN_48_HOURS": "LESS_THAN_48_HOURS",
  "TWO_TO_14_DAYS": "TWO_TO_14_DAYS",
  "MORE_THAN_14_DAYS": "MORE_THAN_14_DAYS",
  "OTHER": "OTHER"
};

const SeverityScore = {
  "NO_PAIN": "NO_PAIN",
  "MILD": "MILD",
  "MODERATE": "MODERATE",
  "SEVERE": "SEVERE"
};

const Sex = {
  "MALE": "MALE",
  "FEMALE": "FEMALE",
  "OTHER": "OTHER"
};

const PresentingSymptoms = {
  "PAIN_AT_REST": "PAIN_AT_REST",
  "PAIN_ON_MOVEMENT": "PAIN_ON_MOVEMENT",
  "PAIN_ON_PALPATION": "PAIN_ON_PALPATION",
  "SWELLING": "SWELLING",
  "BRUISING": "BRUISING",
  "MUSCLE_SPASM": "MUSCLE_SPASM",
  "MUSCLE_WEAKNESS": "MUSCLE_WEAKNESS",
  "JOINT_INSTABILITY": "JOINT_INSTABILITY",
  "TRAUMATIC_EVENT": "TRAUMATIC_EVENT",
  "NONE": "NONE"
};

const HealthHistory = {
  "AGE_12_AND_UNDER": "AGE_12_AND_UNDER",
  "PREGNANCY": "PREGNANCY",
  "BREASTFEEDING": "BREASTFEEDING",
  "CARDIOVASCULAR_DISEASE": "CARDIOVASCULAR_DISEASE",
  "GASTROINTESTINAL_DISORDER": "GASTROINTESTINAL_DISORDER",
  "RENAL_IMPAIRMENT": "RENAL_IMPAIRMENT",
  "BLEEDING_DISORDER": "BLEEDING_DISORDER",
  "HEPATOTOXICITY_RISK": "HEPATOTOXICITY_RISK",
  "NONE": "NONE"
};

const Allergies = {
  "PULMONARY_CONDITION_EG_ASTHMA": "PULMONARY_CONDITION_EG_ASTHMA",
  "ANAPHYLAXIS_URTICARIA_ANGIOEDEMA_ASA_NSAID": "ANAPHYLAXIS_URTICARIA_ANGIOEDEMA_ASA_NSAID",
  "ANAPHYLAXIS_URTICARIA_ANGIOEDEMA_ACETAMINOPHEN": "ANAPHYLAXIS_URTICARIA_ANGIOEDEMA_ACETAMINOPHEN",
  "NONE": "NONE"
};

const UpToDate = {
  "UP_TO_DATE": "UP_TO_DATE",
  "OUTDATED": "OUTDATED"
};

const RedFlagSigns = {
  "OBVIOUS_FRACTURE": "OBVIOUS_FRACTURE",
  "JOINT_DEFORMITY": "JOINT_DEFORMITY",
  "FUNCTIONAL_IMPAIRMENT": "FUNCTIONAL_IMPAIRMENT",
  "MUSCLE_DEFORMITY": "MUSCLE_DEFORMITY",
  "MUSCLE_WEAKNESS": "MUSCLE_WEAKNESS",
  "TRAUMATIC_EVENT": "TRAUMATIC_EVENT",
  "NEUROVASCULAR_COMPROMISE": "NEUROVASCULAR_COMPROMISE",
  "INCREASING_PAIN": "INCREASING_PAIN",
  "PERSISTENT_PAIN": "PERSISTENT_PAIN",
  "HEAD_TRAUMA": "HEAD_TRAUMA",
  "EYE_INJURY": "EYE_INJURY",
  "NOSEBLEED": "NOSEBLEED",
  "PERFORATED_EARDRUM": "PERFORATED_EARDRUM",
  "SYSTEMIC_SIGNS": "SYSTEMIC_SIGNS",
  "ABDOMINAL_PAIN": "ABDOMINAL_PAIN",
  "NONE": "NONE"
};

const BodySide = {
  "LEFT": "LEFT",
  "RIGHT": "RIGHT",
  "CENTRAL": "CENTRAL",
  "OTHER": "OTHER"
};

const Status = {
  "SEE_DOCTOR": "SEE_DOCTOR",
  "PENDING_REVIEW": "PENDING_REVIEW",
  "UNPAID": "UNPAID",
  "PAID": "PAID",
  "SHIPPED": "SHIPPED"
};

const Schedule = {
  "OTC": "OTC",
  "PRESCRIPTION": "PRESCRIPTION"
};

const { User, Patient, PainLog, PainAssessment, TreatmentPlan, Medication, Cart, CartItem, ContactSubmission } = initSchema(schema);

export {
  User,
  Patient,
  PainLog,
  PainAssessment,
  TreatmentPlan,
  Medication,
  Cart,
  CartItem,
  ContactSubmission,
  AreaOfPain,
  Onset,
  SeverityScore,
  Sex,
  PresentingSymptoms,
  HealthHistory,
  Allergies,
  UpToDate,
  RedFlagSigns,
  BodySide,
  Status,
  Schedule
};