import React from 'react';
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';

// import CheckoutForm from './CheckoutForm';

// This is your publishable Stripe key, replace it with your actual key
// const stripePromise = loadStripe('pk_test_yourPublishableKeyHere');

export function GreenPage() {
    return (
        <div className="m-5 sm:w-3/5 4/5">
            <h1 className="text-2xl mt-2">Recommended Treatment</h1>
            <p className="mt-2 text-md">Based on your assessment, we recommend the following medication:</p>
            <div className="mt-4 p-4 border text-white rounded-lg bg-teal-950">
                <h3 className="text-lg">Medication Name: Acetaminophen</h3>
                <p className="text-sm">Dosage: 500mg</p>
                <p className="text-sm">Instructions: Take 2 tablets every 6 hours as needed for pain.</p>
            </div>
            <div className="mt-6">
                <h3 className="text-lg">Purchase Medication</h3>
                <p className="text-md mb-4">Complete your purchase securely below:</p>
                {/*<Elements stripe={stripePromise}>*/}
                {/*    <CheckoutForm />  /!* Render your Stripe checkout form component here *!/*/}
                {/*</Elements>*/}


                <stripe-buy-button
                    buy-button-id="buy_btn_1PLtzpIMdFeu5y8YRNJK8EwB"
                    publishable-key="pk_live_51Nsy5gIMdFeu5y8YslT6j4Xvx8n0ZhdPVc9ZzFSgg6qNfZKbuWUAAyOBgfoojTVttHpGDPvH4Y7lL9iRhHUTiEsg00chtRYNty"
                >
                </stripe-buy-button>
            </div>
        </div>
    );
}
